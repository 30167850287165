import React from 'react';
import Moment from 'moment';

import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import SEO from 'components/seo';
import TermsAndConditionsEN from '@youship/legal/terms-en';
import TermsAndConditionsPT from '@youship/legal/terms-pt';
import TermsInline from '@youship/components/terms-inline';

const lastModifiedDate = Moment().format('MMMM DD, YYYY');

const TermsAndConditionsPage = ({ pageContext: { locale } }) => {
  /* let termsContent = null;

  switch (locale) {
    case 'pt':
      termsContent = <TermsAndConditionsPT />;
      break;
    case 'en':
    default:
      termsContent = <TermsAndConditionsEN />;
  } */

  return (
    <Layout locale={locale}>
      <SEO title="footer.copyright.links.terms_and_conditions" />
      <LegalLayout
        lastModifiedDate={lastModifiedDate}
        title="footer.copyright.links.terms_and_conditions"
      >
      <TermsInline
            locale={locale}
      />
      </LegalLayout>
    </Layout>
  );
};

export default TermsAndConditionsPage;
