import React from 'react';

const TermsAndConditionsPT = () => (
  <>
    <div>
      The terms and conditions (“Terms”) regulate the use and access of users (“User”) to the YOUSHIP technological
      platform (“YOUSHIP”), a registered trademark and made available by the company ShipNow Technologies, Unipessoal Lda,
      headquartered at IPN Incubadora, Rua Pedro Nunes, 3030-199 Coimbra, Portugal, registered under number 513590234.
      <br />
      <br />
      Access to the platform and services (“Services”) can be done through a Mobile Application or Website.
      <br />
      <br />
      The Services are provided through a technological platform that allows users (“Users”) to organize shipment of
      orders with other users who are available to perform the service (“Transporters”). Users and carriers are users of
      the platform and must create a user account in order to access the YOUSHIP platform.
      <br />
      <br />
      YOUSHIP is not a transportation or logistics company and does not provide transportation or logistics services. All
      transport and logistics services are performed by third parties and are not contracted by YOUSHIP.
      <br />
      <br />
      In particular, transport users who make themselves available to carry out a transport service, must know and respect
      the laws that apply in their country, state or city. Users should inquire about laws before performing services
      through YOUSHIP.
      <br />
      <br />
      The user when creating a user account and using the YOUSHIP platform recognizes and accepts the terms and conditions.
    </div>
    <div>
      <h3>
        1. Changes
      </h3>
      <p>
        YOUSHIP reserves the right, in its sole discretion, to modify the Mobile Application, Website or the Services or
        to modify these Terms, at any time and without prior notice. If we modify these Terms, we may post the change on
        the Site or through the Application or otherwise provide notice of the change. By continuing to access or use the
        YOUSHIP platform after posting a change to the Site or through the Application, or after sending a change notice,
        you accept the Terms. If you do not accept the Terms, the only possible option is to stop accessing and using the platform.
      </p>
    </div>
    <div>
      <h3>
        2. Eligibility
      </h3>
      <p>
        The YOUSHIP platform is exclusively for people over 18 years old. Any access or use of the platform by anyone
        under 18 is strictly prohibited. When accessing or using the platform, the user declares and warrants that he is
        over 18 years of age.
      </p>
    </div>
    <div>
      <h3>
        3. Operation
      </h3>
      <p>
        YOUSHIP is not a transportation or logistics company and does not provide transportation or logistics services.
        All transport and logistics services are performed by third parties.
      </p>
      <br />
      <br />
      <p>
        YOUSHIP does not control the content of shipments of orders, conditions and legality of services provided by
        carriers. Thus, users and carriers assume all associated risks.
      </p>
    </div>
    <div>
      <h3>
        4. Damage and loss
      </h3>
      <p>
        O utilizador transportador é o único responsável pela entrega das encomendas e assume todos os danos ou extravios que possam ocorrer.
      </p>
      <br />
      <br />
      <p>
        A YOUSHIP não pode nem assume qualquer responsabilidade por possíveis danos ou extravios de encomendas. Contudo, a
        YOUSHIP poderá mediar a apresentação e resolução de reclamações entre utilizador e transportador.
      </p>
    </div>
    <div>
      <h3>
        5. Pagamentos
      </h3>
      <p>
        O utilizador ao aceitar uma proposta de serviço é realizada uma autorização de pagamento no valor da proposta.
        Após a conclusão do serviço pelo utilizador transportador, o dinheiro é transferido para a YOUSHIP. Mensalmente a
        YOUSHIP transfere ao utilizador transportador o valor dos serviços realizados deduzidos de uma comissão de serviço que varia entre 12% e 20%.
      </p>
    </div>
    <div>
      <h3>
        6. Impostos
      </h3>
      <p>
        O utilizador transportador, entende e concorda que é o único responsável por determinar as suas obrigações em
        matéria de Declarações fiscais e os Impostos que devem ser incluídos, e os Impostos incluídos que devem ser
        cobrados pelos serviços de transporte. O utilizador também é exclusivamente responsável por remeter à autoridade
        competente quaisquer Impostos incluídos ou recebidos por si.
      </p>
    </div>
  </>
);

export default TermsAndConditionsPT;
