import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './styles.scss';

const LegalLayout = ({ children, classNames, lastModifiedDate, title }) => (
  <div className={`legal-layout${classNames ? ` ${classNames}` : ''}`}>
    <div className="container">
      <div className="row">
        <div className="col col-12">
          <h1 className="legal-layout__title">
            <FormattedMessage id={title} />
          </h1>
          <div
            className="legal-layout__content"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

LegalLayout.propTypes = {
  children: PropTypes.element,
  classNames: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  title: PropTypes.string
};

LegalLayout.defaultProps = {
  children: null,
  classNames: null,
  lastModifiedDate: '',
  title: ''
};

export default LegalLayout;
